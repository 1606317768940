import React from "react";
import { Link } from "gatsby";
import { slugify, imageURL } from "../utils";
import { ScoreCircle } from "../components/score-circle";

const Alternative = ({ alternative, cause }) => {
  const { summary, value } = alternative.scoresScoresByBrandIdList.find(
    (score) => score.causeId === cause.id
  ) || { summary: "", value: NaN };
  let parsedScore = parseInt(value);
  let textValue = "";
  if (!Number.isNaN(parsedScore)) {
    if (parsedScore <= 100) {
      textValue = "Great";
    }
    if (parsedScore <= 84) {
      textValue = "Good";
    }
    if (parsedScore <= 60) {
      textValue = "Avg";
    }
    if (parsedScore <= 30) {
      textValue = "Poor";
    }
  } else {
    textValue = "N/A";
    parsedScore = -1;
  }

  const card = (
    <div style={{ maxWidth: "320px", minWidth: "200px" }}>
      <div className="flex items-center gap-4">
        {alternative?.logo && (
          <img
            src={imageURL(alternative.logo)}
            alt=""
            className="w-10 flex-shrink-0"
          />
        )}
        <h2 className="text-lg font-bold leading-tight flex-shrink-0">
          {alternative.name}
        </h2>
        <div style={{ width: "100%" }}>
          <div className="w-10 float-right">
            <ScoreCircle
              width="100%"
              scoreText={textValue}
              score={parsedScore}
            />
          </div>
        </div>
      </div>
      {summary && <div className="mt-4 text-small">{summary}</div>}
    </div>
  );

  return Number.isNaN(parsedScore) ? (
    <div className="rounded-xl p-4 shadow-md bg-white">{card}</div>
  ) : (
    <Link
      to={`/${slugify(alternative.name)}/${slugify(cause.name)}/`}
      className="rounded-xl p-4 shadow-md bg-white transition-all transform hover:-translate-y-1 hover:shadow-lg"
    >
      {card}
    </Link>
  );
};

export default Alternative;
