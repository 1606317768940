import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import useWindowSize from "../components/use-window-size";
import SectionTitle from "../components/section-title";
import { getHeader, getDesc } from "../copy";
import SubscoreCard from "../components/subscore-card";
import { imageURL, scoreData } from "../utils";
import Alternative from "../components/alternative";
import { BrandCard } from "../components/brand-card";

const BrandCause = ({ data }) => {
  const brand = data?.postgres?.scoresBrandById;
  const cause = data?.postgres?.scoresCauseById;
  const hasParent = brand?.inheritsScoresFromId !== null;
  const ownedBrands = data?.postgres?.owned ?? [];

  const score =
    brand.scoresScoresByBrandIdList.length > 0
      ? brand.scoresScoresByBrandIdList[0]
      : brand.scoresBrandByInheritsScoresFromId.scoresScoresByBrandIdList[0];

  const betterAlternatives = brand && getBetterAlternatives(brand, score);

  const score2news = (score) =>
    score.scoresScorenewsByScoreIdList.map((n) => n.scoresNewByNewsId);

  const parentNews = hasParent
    ? brand.scoresBrandByInheritsScoresFromId.scoresScoresByBrandIdList.flatMap(
        score2news
      )
    : [];
  const news = brand.scoresScoresByBrandIdList.flatMap(score2news);

  const allNews = [...new Set([...parentNews, ...news])];
  allNews.sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1));

  const { allFlags, structuredFlags } = getNumericFlags(brand, score);

  const { isMobile } = useWindowSize();

  const title = getHeader(cause.name, brand.name);
  const desc = getDesc(
    cause.name,
    brand.name,
    scoreData(score).textValue,
    score.summary
  );

  return (
    <Layout cause={cause.name}>
      <div className="max-w-screen-xl md:mx-auto p-4 md:p-10">
        <SEO title={title} desc={desc} />
        <section className="flex justify-center md:justify-between items-center flex-wrap gap-8 rounded-xl p-4 shadow-md bg-white">
          <BrandCard
            className="w-full"
            score={score}
            brand={brand}
            cause={cause}
          />
        </section>
        {ownedBrands.length > 0 && (
          <section>
            <SectionTitle>Related brands</SectionTitle>
            <div className="flex flex-wrap py-4 gap-4">
              {ownedBrands.map((ownedBrand) => (
                <div
                  key={ownedBrand.name}
                  className="rounded-xl p-4 shadow-md bg-white"
                >
                  <div style={{ maxWidth: "320px", minWidth: "200px" }}>
                    <div className="flex items-center gap-4">
                      {ownedBrand?.logo && (
                        <img
                          src={imageURL(ownedBrand.logo)}
                          alt=""
                          className="w-10 flex-shrink-0"
                        />
                      )}
                      <h2 className="text-lg font-bold leading-tight flex-shrink-0">
                        {ownedBrand.name}
                      </h2>
                    </div>
                    <div className="mt-4 text-sm text-gray-800">
                      {ownedBrand.name} {ownedBrand.inheritsScoresText}{" "}
                      {brand.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}
        {allFlags.length > 0 && (
          <section>
            <SectionTitle>Detailed information</SectionTitle>
            <div className="flex flex-wrap justify-between pb-4 items-start">
              {isMobile ? (
                <div className="w-full">
                  {Object.values(structuredFlags).map((f) => (
                    <SubscoreCard f={f} brand={brand.name} key={f.id} />
                  ))}
                </div>
              ) : (
                <>
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    {Object.values(structuredFlags)
                      .filter((_, idx) => idx % 2 === 0)
                      .map((f) => (
                        <SubscoreCard f={f} brand={brand.name} key={f.id} />
                      ))}
                  </div>
                  <div style={{ width: "calc(50% - 1rem)" }}>
                    {Object.values(structuredFlags)
                      .filter((_, idx) => idx % 2 !== 0)
                      .map((f) => (
                        <SubscoreCard f={f} brand={brand.name} key={f.id} />
                      ))}
                  </div>
                </>
              )}
            </div>
          </section>
        )}
        <section>
          <SectionTitle>Latest news</SectionTitle>
          <div className="flex flex-wrap py-4 gap-4">
            {allNews.map((n) => (
              <a
                href={n.link}
                key={n.link}
                rel="noopener"
                className="rounded-xl p-4 shadow-md bg-white transition-all transform hover:-translate-y-1 hover:shadow-lg"
                style={{ width: isMobile ? "100%" : "calc(33.33% - 0.67rem)" }}
              >
                <div className="flex flex-col justify-between h-full">
                  <h2 className="text-lg font-bold leading-tight">{n.text}</h2>
                  {n.summary && (
                    <div className="mt-4 items-center text-sm">
                      <blockquote>{n.summary}</blockquote>
                    </div>
                  )}
                  <div className=" flex justify-between mt-4 items-center">
                    <p>by {n.source}</p>
                    <time
                      className=" py-2 px-3 rounded-full bg-bc-pink"
                      dateTime={n.publishedAt}
                    >
                      {n.publishedAt.slice(0, 10)}
                    </time>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </section>
        {betterAlternatives && Object.values(betterAlternatives).length > 0 && (
          <section>
            <SectionTitle>Better Alternatives</SectionTitle>
            <div className="flex flex-wrap py-4 gap-4 items-start">
              {Object.values(betterAlternatives).map((alternative) => (
                <Alternative
                  alternative={alternative}
                  cause={cause}
                  key={alternative.id}
                />
              ))}
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
};

const getNumericFlags = (brand, score) => {
  const hasParent = brand?.inheritsScoresFromId !== null;
  const score2flags = (score) =>
    score.scoresNumericsubscoresByParentScoreIdList.concat(
      score.scoresTextsubscoresByParentScoreIdList
    );

  const parentFlags = hasParent
    ? brand.scoresBrandByInheritsScoresFromId.scoresScoresByBrandIdList.flatMap(
        score2flags
      )
    : [];
  const flags = brand.scoresScoresByBrandIdList.flatMap(score2flags);
  const allFlags = [...new Set([...parentFlags, ...flags])];
  const structuredFlags = allFlags.reduce((sf, flag) => {
    const p = flag.path ? flag.path.split(">") : [];

    let currentObj = sf;
    for (let subId of p) {
      if (subId === "") break;
      subId in currentObj || (currentObj[subId] = { children: {} });
      currentObj = currentObj[subId]["children"];
    }

    flag.id in currentObj
      ? (currentObj[flag.id] = {
          children: {},
          ...currentObj[flag.id],
          ...flag,
        })
      : (currentObj[flag.id] = { children: {}, ...flag });

    return sf;
  }, {});

  return { allFlags, structuredFlags };
};

// augment brands with a list of better alternatives
const getBetterAlternatives = (brand, score) => {
  const filterCompetitors = (comp) => {
    let competitorScores =
      ("scoresBrandByFromBrandId" in comp
        ? comp.scoresBrandByFromBrandId
        : comp.scoresBrandByToBrandId
      ).scoresScoresByBrandIdList ?? [];

    const competitorScore = competitorScores.find(
      (s) => s.causeId === score.causeId
    );

    return (
      parseInt(competitorScore ? competitorScore.value : 50) >
      parseInt(score.value)
    );
  };

  const fromAlt = (brand?.scoresBrandCompetitorsByFromBrandIdList ?? [])
    .filter(filterCompetitors)
    .map((comp) => comp.scoresBrandByToBrandId);
  const toAlt = (brand?.scoresBrandCompetitorsByToBrandIdList ?? [])
    .filter(filterCompetitors)
    .map((comp) => comp.scoresBrandByFromBrandId);
  const groupCompetitorsById = (object, alt) => {
    object[alt.id] = alt;
    return object;
  };
  const betterAlternatives = {
    ...fromAlt.reduce(groupCompetitorsById, {}),
    ...toAlt.reduce(groupCompetitorsById, {}),
  };

  return betterAlternatives;
};

export const query = graphql`
  query BrandCauseQuery(
    $brandId: PostGraphile_UUID!
    $causeId: PostGraphile_UUID!
  ) {
    postgres {
      owned: allScoresBrandsList(
        condition: { inheritsScoresFromId: $brandId }
      ) {
        name
        logo
        inheritsScoresText
      }
      scoresBrandById(id: $brandId) {
        id
        inheritsScoresFromId
        inheritsScoresText
        website
        scoresBrandByInheritsScoresFromId {
          name
          logo

          scoresScoresByBrandIdList(condition: { causeId: $causeId }) {
            brandId
            causeId
            value
            summary
            scoresNumericsubscoresByParentScoreIdList {
              id
              name
              subscore
              maxscore
              path
            }
            scoresTextsubscoresByParentScoreIdList {
              id
              name
              subscore
            }
            scoresScorenewsByScoreIdList {
              scoresNewByNewsId {
                source
                text
                link
                summary
                updatedAt
                publishedAt
              }
            }
          }
        }
        name
        logo
        scoresBrandCompetitorsByFromBrandIdList {
          scoresBrandByToBrandId {
            id
            name
            logo
            scoresScoresByBrandIdList(condition: { causeId: $causeId }) {
              value
              summary
              causeId
            }
          }
        }
        scoresBrandCompetitorsByToBrandIdList {
          scoresBrandByFromBrandId {
            id
            name
            logo
            scoresScoresByBrandIdList(condition: { causeId: $causeId }) {
              value
              summary
              causeId
            }
          }
        }
        scoresScoresByBrandIdList(condition: { causeId: $causeId }) {
          brandId
          causeId
          value
          summary
          scoresNumericsubscoresByParentScoreIdList {
            id
            name
            subscore
            maxscore
            path
          }
          scoresTextsubscoresByParentScoreIdList {
            id
            name
            subscore
          }
          scoresScorenewsByScoreIdList {
            scoresNewByNewsId {
              source
              text
              link
              summary
              updatedAt
              publishedAt
            }
          }
        }
      }
      scoresCauseById(id: $causeId) {
        name
        id
      }
    }
  }
`;

export default BrandCause;
