import React from "react";
import thumbUp from "../resources/images/thumb_up.svg";
import thumbDown from "../resources/images/thumb_down.svg";
import { getSubscoreText, getSubscoreTitle } from "../copy";

// consider only 1-level hierarchy in subscores for now
const SubscoreCard = ({ f, brand }) => {
  return (
    <div className="rounded-xl p-4 shadow-md bg-white mt-8">
      <h2 className="text-lg font-bold mb-2 border-gray-200 border-b pb-2 leading-tight">
        {getSubscoreTitle(f.name, brand)}
      </h2>
      <div className="leading-snug">
        {getSubscoreText(f.name, brand, f.subscore, f.maxscore)}
      </div>
      <div>
        {Object.values(f?.children ?? {}).map((subsub) => (
          <FlagBadge f={subsub} key={subsub.id} />
        ))}
      </div>
    </div>
  );
};

const FlagBadge = ({ f }) => {
  return (
    <div className="flex justify-between items-center gap-4 mt-4 bg-gray-100 rounded-md py-2 px-4">
      <p className="font-light text-sm">{f.name}</p>
      {f.subscore === f.maxscore ? (
        <img src={thumbUp} alt="Positive" />
      ) : f.subscore === 0 ? (
        <img src={thumbDown} alt="Negative" />
      ) : (
        <p className="text-yellow-600">AVG</p>
      )}
    </div>
  );
};

export default SubscoreCard;
